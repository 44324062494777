
import axios from 'axios';
import { TransactionsResponse } from '../../common/interfaces/transaction.interface';


export const wallet = {
    state: {
        wallet: {},
        transactions: [],
    },
    reducers: {
        setWallet(state: any, payload: any) {
            return {
                ...state,
                wallet: payload
            }
        },
        setWalletTransactions(state: any, payload: any) {
            return {
                ...state,
                transactions: payload
            }
        }
    },
    effects: (dispatch: any) => {
        return {
            fetchWallet:  () => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet`;
                    axios.get(url).then((response) => {
                        resolve(response.data);
                        dispatch.wallet.setWallet(response.data);
                    }).catch((error) => {
                        reject(error);
                    });
                });
            },
            fetchWalletTransactions:  (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/transactions`;
                    axios.post(url, payload).then((response) => {
                        const data: TransactionsResponse = response.data;
                        resolve(data.result);
                        dispatch.wallet.setWalletTransactions(data.result);
                    }).catch((error) => {
                        reject(error);
                    });
                });
            },
            createPesapalTransaction: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/payments/pesapal`;
                    axios.post(url, payload).then((response) => {
                        resolve(response.data);
                    }).catch((error) => {
                        reject(error);
                    });
                });
            },
            createFlutterwaveTransaction: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/payments/flutterwave`;
                    axios.post(url, payload).then((response) => {
                        resolve(response.data);
                    }).catch((error) => {
                        reject(error);
                    });
                });
            },
            createSTKUrl: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/payments/mpesa`;
                    axios.post(url, payload).then((response) => {
                        resolve(response.data);
                    }).catch((error) => {
                        reject(error);
                    });
                });
            },
        }
    },
}